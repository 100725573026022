import * as mixpanelOriginal from "mixpanel-browser";

let mixpanel = {
  track: () => {},
  people: {
    set: () => {},
  },
} as unknown as typeof mixpanelOriginal;

if (process.env.NODE_ENV === "production") {
  mixpanelOriginal.init("c42e61b5d298bee0ce9cdf45598360f0", {
    track_pageview: true,
  });
  mixpanel = mixpanelOriginal;
  mixpanel.people.set({ Plan: "Free" });
}

export default mixpanel;
