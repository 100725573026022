import { Button, TextField } from "@mui/material";
import { useState } from "react";
import {
  demoVideoUrl,
  showExampleStore,
  showSignUpStore,
  useVideoUrlState,
} from "./stores";
import mixpanel from "./mixpanel";

export const Form = () => {
  const { setShowExample } = showExampleStore();
  const [localUrl, setLocalUrl] = useState("");
  const { setVideoUrl } = useVideoUrlState();
  const { setShowSignUp } = showSignUpStore();

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const videoUrl = localUrl;
        mixpanel.track("analyze-comments", { videoUrl });
        setVideoUrl(videoUrl);
        setShowExample(false);
        setShowSignUp(true);
      }}
    >
      <TextField
        InputLabelProps={{ required: false }} // removes asterisk
        fullWidth
        label="Enter any YouTube video URL"
        value={localUrl}
        placeholder={demoVideoUrl}
        onChange={(event) => setLocalUrl(event.target.value)}
        margin="normal"
        type="url"
        variant="outlined"
        required // TODO: maybe add more/better validation?
      />
      <Button type="submit" fullWidth variant="contained" color="primary">
        Analyze comments for Free
      </Button>
    </form>
  );
};
