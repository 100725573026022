import { useMutation, useQuery } from "@tanstack/react-query";
import getYouTubeID from "get-youtube-id";
import { z } from "zod";
import { chartDataSchema } from "./stores";

const videoInfoSchema = z.object({
  width: z.number(),
  author_name: z.string(),
  title: z.string(),
  author_url: z.string(),
  thumbnail_url: z.string(),
  height: z.number(),
});
export type VideoInfo = z.infer<typeof videoInfoSchema>;

export const videoInfoDemoData: VideoInfo = {
  width: 480,
  author_name: "Y Combinator",
  title: "How Elon Musk Spends His Time", // cspell:words Elon
  author_url: "https://www.youtube.com/channel/UCcefcZRL2oaA_uBNeo5UOWg",
  thumbnail_url: "https://i.ytimg.com/vi/qszGzNoopTc/hqdefault.jpg",
  height: 270,
};
export const useVideoInfoQuery = (videoUrl: string) => {
  const query = useQuery({
    queryKey: [`youtube-oembed`, videoUrl], // cspell:words oembed
    queryFn: async () => {
      if (!videoUrl) {
        throw new Error("No video URL provided");
      }
      const response = await fetch(
        `https://www.youtube.com/oembed?url=${videoUrl}`
      );
      return videoInfoSchema.parse(await response.json());
    },
  });

  return query;
};

// Schema for Schema$CommentSnippetAuthorChannelId
const CommentSnippetAuthorChannelIdSchema = z.object({
  value: z.string().nullable().optional(),
});

// Schema for Schema$CommentSnippet
const CommentSnippetSchema = z.object({
  authorChannelId: CommentSnippetAuthorChannelIdSchema.optional(),
  authorChannelUrl: z.string().nullable().optional(),
  authorDisplayName: z.string().nullable().optional(),
  authorProfileImageUrl: z.string().nullable().optional(),
  canRate: z.boolean().nullable().optional(),
  channelId: z.string().nullable().optional(),
  likeCount: z.number().nullable().optional(),
  moderationStatus: z.string().nullable().optional(),
  parentId: z.string().nullable().optional(),
  publishedAt: z.string().nullable().optional(),
  textDisplay: z.string().nullable().optional(),
  textOriginal: z.string().nullable().optional(),
  updatedAt: z.string().nullable().optional(),
  videoId: z.string().nullable().optional(),
  viewerRating: z.string().nullable().optional(),
});

// Schema for Schema$Comment
const CommentSchema = z.object({
  etag: z.string().nullable().optional(),
  id: z.string().nullable().optional(),
  kind: z.literal("youtube#comment").nullable().optional(),
  snippet: CommentSnippetSchema, //.optional(),
});

// Schema for Schema$CommentThreadSnippet
const CommentThreadSnippetSchema = z.object({
  canReply: z.boolean().nullable().optional(),
  channelId: z.string().nullable().optional(),
  isPublic: z.boolean().nullable().optional(),
  topLevelComment: CommentSchema.optional(),
  totalReplyCount: z.number().nullable().optional(),
  videoId: z.string().nullable().optional(),
});

// Schema for Schema$CommentThread
const CommentThreadSchema = z.object({
  etag: z.string().nullable().optional(),
  id: z.string().nullable().optional(),
  kind: z.literal("youtube#commentThread").nullable().optional(),
  snippet: CommentThreadSnippetSchema.optional(),
  replies: z
    .object({
      comments: z.array(CommentSchema).optional(),
    })
    .optional(),
});

export const analyzeCommentsFreeSchema = z.object({
  theMostPositiveSentimentValue: z.number(),
  theMostPositiveComment: CommentThreadSchema,
  theMostNegativeSentimentValue: z.number(),
  theMostNegativeComment: CommentThreadSchema,
  chartData: chartDataSchema,
  commentsAnalyzed: z.number(),
  totalComments: z.number(),
});
type AnalyzeCommentsFree = z.infer<typeof analyzeCommentsFreeSchema>;

export const analyzeCommentsFreeDemoData: AnalyzeCommentsFree = {
  theMostPositiveSentimentValue: 0.9951,
  theMostPositiveComment: {
    kind: "youtube#commentThread",
    etag:
      // cspell:disable-next-line
      "cYR-H4jryumZQVTK49w55L9UP2A",
    // cspell:disable-next-line
    id: "Ugyui5B1aQ8Utc1yHkJ4AaABAg",
    snippet: {
      // cspell:disable-next-line
      channelId: "UCcefcZRL2oaA_uBNeo5UOWg",
      videoId: "qszGzNoopTc",
      topLevelComment: {
        kind: "youtube#comment",
        etag: "EwPE-O8s6UaH9qmc67n0cpGCFeI",
        // cspell:disable-next-line
        id: "Ugyui5B1aQ8Utc1yHkJ4AaABAg",
        snippet: {
          // cspell:disable-next-line
          channelId: "UCcefcZRL2oaA_uBNeo5UOWg",
          videoId: "qszGzNoopTc",
          textDisplay:
            "So busy... But doing what&#39;s lovely to him. Nice!!! 👍 YC",
          textOriginal:
            "So busy... But doing what's lovely to him. Nice!!! 👍 YC",
          // cspell:disable-next-line
          authorDisplayName: "@martinstine",
          authorProfileImageUrl:
            "https://yt3.ggpht.com/9W9T_pYx_knnFdnD5lozTAISETmGc_vG5hE0P6GXfzXBFjcgxSROv205R-nFsphRVn6RdETctA8=s48-c-k-c0x00ffffff-no-rj",
          authorChannelUrl: "http://www.youtube.com/@martinstine",
          authorChannelId: {
            value: "UCOm732ZKeSv2XniRMtB-bvg",
          },
          canRate: true,
          viewerRating: "none",
          likeCount: 0,
          publishedAt: "2023-08-29T15:05:38Z",
          updatedAt: "2023-08-29T15:05:38Z",
        },
      },
      canReply: true,
      totalReplyCount: 0,
      isPublic: true,
    },
  },
  theMostNegativeSentimentValue: -0.8302,
  theMostNegativeComment: {
    kind: "youtube#commentThread",
    // cspell:disable-next-line
    etag: "U33zXGRroLkTeduO2hoPxZbzH4o",
    // cspell:disable-next-line
    id: "UgzflV9Hv79WU2FBCYd4AaABAg",
    snippet: {
      // cspell:disable-next-line
      channelId: "UCcefcZRL2oaA_uBNeo5UOWg",
      videoId: "qszGzNoopTc",
      topLevelComment: {
        kind: "youtube#comment",
        // cspell:disable-next-line
        etag: "-IMiyD04Riwvg_Tl6wfU-tT30vE",
        // cspell:disable-next-line
        id: "UgzflV9Hv79WU2FBCYd4AaABAg",
        snippet: {
          // cspell:disable-next-line
          channelId: "UCcefcZRL2oaA_uBNeo5UOWg",
          videoId: "qszGzNoopTc",
          // cspell:disable-next-line
          textDisplay: "this interviwer is bad at interviewing",
          // cspell:disable-next-line
          textOriginal: "this interviwer is bad at interviewing",
          authorDisplayName: "@stranger-mx1mc",
          authorProfileImageUrl:
            "https://yt3.ggpht.com/FyMD3gM86y0Q-bIUZg33_HwtLcVsYWZaC-aWoIW2scWfo1z6pv6x5NAXN96fOeCf7t4U8rli=s48-c-k-c0x00ffffff-no-rj",
          authorChannelUrl: "http://www.youtube.com/@stranger-mx1mc",
          authorChannelId: {
            // cspell:disable-next-line
            value: "UCYBfOE2qwfoYgnmTsyfxB7A",
          },
          canRate: true,
          viewerRating: "none",
          likeCount: 1,
          publishedAt: "2021-05-15T15:08:05Z",
          updatedAt: "2021-05-15T15:08:05Z",
        },
      },
      canReply: true,
      totalReplyCount: 0,
      isPublic: true,
    },
  },
  chartData: {
    NEGATIVE: 98,
    SLIGHTLY_NEGATIVE: 203,
    NEUTRAL: 795,
    SLIGHTLY_POSITIVE: 651,
    POSITIVE: 401,
  },
  commentsAnalyzed: 2148,
  totalComments: 2148,
};
export const useAnalyzeCommentsFreeQuery = (videoUrl: string) => {
  return useQuery({
    queryKey: [`my-api`, `comments/${videoUrl}/free`],
    queryFn: async () => {
      const videoId = getYouTubeID(videoUrl);
      if (!videoId) {
        throw new Error("Invalid video URL");
      }
      const response = await fetch(
        new URL(`comments/${videoId}/free`, process.env.REACT_APP_API_BASE_URL)
      );
      return analyzeCommentsFreeSchema.parse(await response.json());
    },
  });
};

export const useSubscribeMutation = () =>
  useMutation({
    mutationFn: async (email: string) => {
      const response = await fetch(
        new URL(`subscribe`, process.env.REACT_APP_API_BASE_URL),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to subscribe");
      }
      const data = await response.json();
      if (!data.ok) {
        throw new Error("Failed to subscribe");
      }
    },
  });
