import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Snackbar,
  TextField,
} from "@mui/material";
import { useState } from "react";
import mixpanel from "./mixpanel";
import { useSubscribeMutation } from "./queries";
import { showSubscribeDialogStore } from "./stores";

export default function SubscribeDialog() {
  const { showSubscribeDialog, setShowSubscribeDialog } =
    showSubscribeDialogStore();

  const handleClose = (source: string) => {
    mixpanel.track("subscribe-dialog-close", { source });
    setShowSubscribeDialog(false);
  };

  const subscribeMutation = useSubscribeMutation();

  const [openOk, setOpenOk] = useState(false);

  const handleCloseOk = () => {
    setOpenOk(false);
  };

  return (
    <>
      <Snackbar open={openOk} autoHideDuration={5000} onClose={handleCloseOk}>
        <Alert
          onClose={handleCloseOk}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          You joined waitlist! We will be in touch soon.
        </Alert>
      </Snackbar>
      <Dialog
        open={showSubscribeDialog}
        onClose={() => handleClose("dialog close")}
        PaperProps={{
          component: "form",
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const email = formJson.email;
            mixpanel.track("subscribe-dialog-submitted-attempt", { email });
            subscribeMutation
              .mutateAsync(email)
              .then(() => {
                mixpanel.track("subscribe-dialog-submitted-success", { email });
                handleClose("submitted");
                setOpenOk(true);
              })
              .catch(() => {
                mixpanel.track("subscribe-dialog-submitted-error", { email });
              });
          },
        }}
      >
        <DialogTitle>Get early access by joining our waitlist</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We are working hard to launch YouTube Comments Analyzer. It will
            Analyze all comments, and use more advanced AI models like ChatGPT
            or LLAMA to detect common themes, sentiment, and more.
          </DialogContentText>

          {subscribeMutation.isError && (
            <DialogContentText color="error" sx={{ marginTop: 1 }}>
              There was an error, please try again. If the problem persists,
              please{" "}
              <a
                href="mailto:maxim@mazurok.com?Subject=Get%20Early%20Access%20YouTube%20Comments%20Analyzer"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact&nbsp;support
              </a>
            </DialogContentText>
          )}

          <TextField
            InputLabelProps={{ required: false }} // removes asterisk
            required
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            variant="outlined"
            onChange={(event) => {
              mixpanel.track("subscribe-dialog-email-typed", {
                email: event.target.value,
              });
            }}
          />
        </DialogContent>

        {subscribeMutation.isPending && <LinearProgress />}

        <DialogActions>
          <Button
            onClick={() => handleClose("clicked cancel button")}
            disabled={subscribeMutation.isPending}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={subscribeMutation.isPending}>
            Get early access
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
