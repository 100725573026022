import { Tooltip, Typography } from "@mui/material";
import { Comment, CommentType } from "./Comment";
import { PayWall } from "./PayWall";
import { useAnalyzeCommentsFreeQuery } from "./queries";
import { useVideoUrlState } from "./stores";

export const Comments = () => {
  const { videoUrl } = useVideoUrlState();
  const { data, isFetching, isError, error } =
    useAnalyzeCommentsFreeQuery(videoUrl);

  return isError ? (
    <div>
      <Typography color="error">Error loading comments</Typography>
      <Typography color="error" variant="body2">
        Please try again later or{" "}
        <a
          href="mailto:maxim@mazurok.com?Subject=Sentiment%20Chart%20Error"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact support
        </a>
        .
      </Typography>
      <Typography color="error" variant="body2">
        <Tooltip title={error.message}>
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            Error Details
          </span>
        </Tooltip>
      </Typography>
    </div>
  ) : (
    <>
      <Comment
        type={CommentType.NEGATIVE}
        data={data}
        isFetching={isFetching}
      />
      <Comment
        type={CommentType.POSITIVE}
        data={data}
        isFetching={isFetching}
      />
      <PayWall data={data} isFetching={isFetching} />
    </>
  );
};
