import { green, grey, orange, red } from "@mui/material/colors";
import ReactApexChart from "react-apexcharts";
import "./SentimentChart.scss";

export enum SentimentChartDataKey {
  NEGATIVE = "NEGATIVE",
  SLIGHTLY_NEGATIVE = "SLIGHTLY_NEGATIVE",
  NEUTRAL = "NEUTRAL",
  SLIGHTLY_POSITIVE = "SLIGHTLY_POSITIVE",
  POSITIVE = "POSITIVE",
}

const emoji: {
  [key in SentimentChartDataKey]: string;
} = {
  [SentimentChartDataKey.NEGATIVE]: "😡",
  [SentimentChartDataKey.SLIGHTLY_NEGATIVE]: "\ud83d\ude41",
  [SentimentChartDataKey.NEUTRAL]: "😐",
  [SentimentChartDataKey.SLIGHTLY_POSITIVE]: "🙂",
  [SentimentChartDataKey.POSITIVE]: "😍",
};

const labels: {
  [key: string]: string;
} = {
  [SentimentChartDataKey.NEGATIVE]: "Negative",
  [SentimentChartDataKey.SLIGHTLY_NEGATIVE]: "Slightly negative",
  [SentimentChartDataKey.NEUTRAL]: "Neutral",
  [SentimentChartDataKey.SLIGHTLY_POSITIVE]: "Slightly positive",
  [SentimentChartDataKey.POSITIVE]: "Positive",
};

const labelsOrder: SentimentChartDataKey[] = [
  SentimentChartDataKey.NEGATIVE,
  SentimentChartDataKey.SLIGHTLY_NEGATIVE,
  SentimentChartDataKey.NEUTRAL,
  SentimentChartDataKey.SLIGHTLY_POSITIVE,
  SentimentChartDataKey.POSITIVE,
];

export interface SentimentChartProps {
  data: {
    [key in SentimentChartDataKey]: number;
  };
}

type ApexSeries = {
  name: string;
  data: number[];
}[];

export const SentimentChart = ({ data }: SentimentChartProps) => {
  const series: ApexSeries = Object.values(data).map((value, index, array) => ({
    name: labels[labelsOrder[index]],
    data: [
      ...Array(index).fill(null),
      value,
      ...Array(array.length - index - 1).fill(null),
    ],
  }));

  const options = {
    plotOptions: {
      bar: {
        columnWidth: "100%",
      },
    },
    chart: {
      id: "sentiment-chart",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    colors: [red[500], orange[500], grey[500], green[600], green[900]],
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    yaxis: {
      // cspell:words yaxis
      show: false,
    },
    xaxis: {
      // cspell:words xaxis
      categories: [
        emoji[SentimentChartDataKey.NEGATIVE],
        emoji[SentimentChartDataKey.SLIGHTLY_NEGATIVE],
        emoji[SentimentChartDataKey.NEUTRAL],
        emoji[SentimentChartDataKey.SLIGHTLY_POSITIVE],
        emoji[SentimentChartDataKey.POSITIVE],
      ],
      labels: {
        style: {
          fontSize: "20px",
          fontFamily: "color-emoji",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height={320}
    />
  );
};
