import {
  Box,
  Button,
  LinearProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import mixpanel from "./mixpanel";
import { useAnalyzeCommentsFreeQuery } from "./queries";
import { showSignUpStore, showSubscribeDialogStore } from "./stores";

type PayWallProps = {
  data: ReturnType<typeof useAnalyzeCommentsFreeQuery>["data"];
  isFetching: boolean;
};

export const PayWall = ({ data, isFetching }: PayWallProps) => {
  const { showSignUp } = showSignUpStore();
  const { setShowSubscribeDialog } = showSubscribeDialogStore();

  return (
    <Box mt={4}>
      {isFetching ? (
        <>
          <Skeleton />
          <LinearProgress variant="indeterminate" />
        </>
      ) : (
        <>
          {/* TODO: add "all comments analyzed" with a green tick for paid/demo */}
          <Typography variant="body2" gutterBottom>
            {data!.commentsAnalyzed.toLocaleString("en-US")} out of{" "}
            {data!.totalComments.toLocaleString("en-US")} total comments
            analyzed
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.max(
              10, // at least 10% to show some progress, otherwise it looks like it's empty for 629821 comments, for example
              (data!.commentsAnalyzed / data!.totalComments) * 100
            )}
          />
        </>
      )}

      {showSignUp && (
        <Box mt={2}>
          {isFetching ? (
            <Skeleton variant="rounded" height={37} />
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => {
                setShowSubscribeDialog(true);
                mixpanel.track("sign-up-click");
              }}
            >
              Sign up to analyze all comments
            </Button>
          )}
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              marginTop: 1,
            }}
          >
            {isFetching ? (
              <Skeleton />
            ) : (
              <>
                For only two dollars per month we will analyze{" "}
                <strong>all comments</strong> for you, for{" "}
                <strong>any videos</strong>!
              </>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
