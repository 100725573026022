import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { SentimentChart } from "./SentimentChart";
import { useAnalyzeCommentsFreeQuery } from "./queries";
import { useVideoUrlState } from "./stores";

export const SentimentChartData = () => {
  const { videoUrl } = useVideoUrlState();
  const { data, isFetching, isError, error } =
    useAnalyzeCommentsFreeQuery(videoUrl);

  return isError ? (
    <Box sx={{ marginBottom: 2, marginTop: 2 }}>
      <Typography color="error">Error loading sentiment chart</Typography>
      <Typography color="error" variant="body2">
        Please try again later or{" "}
        <a
          href="mailto:maxim@mazurok.com?Subject=Sentiment%20Chart%20Error"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact support
        </a>
        .
      </Typography>
      <Typography color="error" variant="body2">
        <Tooltip title={error.message}>
          <span style={{ textDecoration: "underline", cursor: "pointer" }}>
            Error Details
          </span>
        </Tooltip>
      </Typography>
    </Box>
  ) : isFetching ? (
    <Box sx={{ marginBottom: 2, marginTop: 2 }}>
      <Box sx={{ display: "flex", alignItems: "flex-end", gap: "8px" }}>
        <Skeleton variant="rounded" width="100%" height={60} />
        <Skeleton variant="rounded" width="100%" height={110} />
        <Skeleton variant="rounded" width="100%" height={240} />
        <Skeleton variant="rounded" width="100%" height={160} />
        <Skeleton variant="rounded" width="100%" height={115} />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "space-around",
          marginTop: 1,
        }}
      >
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
      </Box>
    </Box>
  ) : (
    <SentimentChart data={data!.chartData} />
  );
};
