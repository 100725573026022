import {
  Box,
  CardMedia,
  Collapse,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useVideoInfoQuery } from "./queries";
import { showExampleStore, useVideoUrlState } from "./stores";

export const Info = () => {
  const { videoUrl } = useVideoUrlState();

  const {
    data: videoInfo,
    isError,
    isFetching,
    error,
  } = useVideoInfoQuery(videoUrl);

  const { showExample } = showExampleStore();

  return (
    <Box sx={{ marginTop: 4 }}>
      {isError ? (
        <>
          <Typography color="error">Error loading video info</Typography>
          <Typography color="error" variant="body2">
            Please make sure the URL is in a correct format. For example,
            "https://www.youtube.com/watch?v=qszGzNoopTc".{" "}
          </Typography>
          <Typography color="error" variant="body2">
            If it still doesn't work -{" "}
            <a
              href="mailto:maxim@mazurok.com?Subject=Sentiment%20Chart%20Error"
              target="_blank"
              rel="noopener noreferrer"
            >
              contact support
            </a>
            .
          </Typography>
          <Typography color="error" variant="body2">
            <Tooltip title={error.message}>
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                Error Details
              </span>
            </Tooltip>
          </Typography>
        </>
      ) : (
        <>
          <Collapse orientation="vertical" in={showExample} collapsedSize={0}>
            <Typography variant="body2" color="textSecondary">
              Example results:
            </Typography>
          </Collapse>
          <Box sx={{ marginTop: 1 }}>
            <ListItem disableGutters>
              <ListItemAvatar sx={{ marginRight: 1, position: "relative" }}>
                <Skeleton
                  variant="rectangular"
                  width={100}
                  style={{
                    paddingTop:
                      ((videoInfo?.height ?? 270) / (videoInfo?.width ?? 480)) *
                      100,
                    borderRadius: 6,
                    ...(!isFetching
                      ? {
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: 1,
                        }
                      : {}),
                  }}
                />
                {!isFetching && (
                  <CardMedia
                    style={{
                      paddingTop: `${(videoInfo!.height / videoInfo!.width) * 100}%`,
                      position: "relative",
                      zIndex: 2,
                      width: 100,
                      borderRadius: 6,
                    }}
                    image={videoInfo!.thumbnail_url}
                    title={`${videoInfo!.author_name} - ${videoInfo!.title}`}
                  />
                )}
              </ListItemAvatar>

              <ListItemText
                primary={
                  isFetching ? (
                    <Skeleton width="60%" />
                  ) : (
                    <Link
                      href={videoUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        fontWeight: 500,
                        color: "rgb(13,13,13)",
                        textDecoration: "none",
                      }}
                    >
                      {videoInfo!.title}
                    </Link>
                  )
                }
                secondary={
                  isFetching ? (
                    <Skeleton width="40%" />
                  ) : (
                    <Link
                      href={videoInfo!.author_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "rgba(17,17,17, 0.8)",
                        textDecoration: "none",
                      }}
                    >
                      {videoInfo!.author_name}
                    </Link>
                  )
                }
              />
            </ListItem>
          </Box>
        </>
      )}
    </Box>
  );
};
