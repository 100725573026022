import { z } from "zod";
import { create } from "zustand";
import { SentimentChartDataKey } from "./SentimentChart";

export const chartDataSchema = z.object({
  [SentimentChartDataKey.NEGATIVE]: z.number(),
  [SentimentChartDataKey.SLIGHTLY_NEGATIVE]: z.number(),
  [SentimentChartDataKey.NEUTRAL]: z.number(),
  [SentimentChartDataKey.SLIGHTLY_POSITIVE]: z.number(),
  [SentimentChartDataKey.POSITIVE]: z.number(),
});
export type ChartData = z.infer<typeof chartDataSchema>;

interface VideoUrlState {
  videoUrl: string;
  setVideoUrl: (videoUrl: string) => void;
}
export const demoVideoUrl = "https://www.youtube.com/watch?v=qszGzNoopTc";
export const useVideoUrlState = create<VideoUrlState>((set) => ({
  videoUrl: demoVideoUrl,
  setVideoUrl: (videoUrl) => set({ videoUrl }),
}));

export const showExampleStore = create<{
  showExample: boolean;
  setShowExample: (showExample: boolean) => void;
}>((set) => ({
  showExample: true,
  setShowExample: (showExample) => set({ showExample }),
}));

export const showSignUpStore = create<{
  showSignUp: boolean;
  setShowSignUp: (showSignUp: boolean) => void;
}>((set) => ({
  showSignUp: false,
  setShowSignUp: (showSignUp) => set({ showSignUp }),
}));

export const showSubscribeDialogStore = create<{
  showSubscribeDialog: boolean;
  setShowSubscribeDialog: (showSubscribeDialog: boolean) => void;
}>((set) => ({
  showSubscribeDialog: false,
  setShowSubscribeDialog: (showSubscribeDialog) => set({ showSubscribeDialog }),
}));
