import { ThumbUp } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Theme,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import parse, { DOMNode, Element, domToReact } from "html-react-parser";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { withStyles } from "tss-react/mui";
import { useAnalyzeCommentsFreeQuery } from "./queries";
import { useVideoUrlState } from "./stores";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

export enum CommentType {
  POSITIVE = "positive",
  NEGATIVE = "negative",
}

export interface CommentProps {
  type: CommentType;
  isFetching: boolean;
  data: ReturnType<typeof useAnalyzeCommentsFreeQuery>["data"];
}

const YTHighlightBadge = withStyles(Chip, ({ spacing }: Theme) => ({
  root: {
    borderRadius: spacing(1 / 4),
    height: "auto",
    marginBottom: spacing(1),
  },
  label: {
    lineHeight: 1.4,
    paddingLeft: spacing(1 / 2),
    paddingRight: spacing(1 / 2),
  },
}));

const RenderComment = ({ text }: { text: string }) => (
  <span>
    {parse(text, {
      replace: (domNode) => {
        domNode = domNode as Element;
        if (domNode.name === "a" && domNode.attribs) {
          return (
            <a
              href={domNode.attribs.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {domToReact(domNode.children as DOMNode[])}
            </a>
          );
        }
      },
    })}
  </span>
);

export const Comment = ({ type, data, isFetching }: CommentProps) => {
  const { videoUrl } = useVideoUrlState();

  const commentData =
    type === CommentType.POSITIVE
      ? data?.theMostPositiveComment
      : data?.theMostNegativeComment;

  const snippet = commentData?.snippet?.topLevelComment?.snippet;

  return (
    <div>
      <ListItem disableGutters style={{ display: "block" }}>
        <Box display="flex" alignItems="flex-start">
          <ListItemAvatar>
            {isFetching ? (
              <Skeleton variant="circular" width={40} height={40} />
            ) : (
              <Link
                href={snippet!.authorChannelUrl ?? undefined}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Avatar
                  alt={snippet!.authorDisplayName ?? undefined}
                  src={snippet!.authorProfileImageUrl ?? undefined}
                />
              </Link>
            )}
          </ListItemAvatar>
          <Box width="100%">
            <ListItemText
              primary={
                isFetching ? (
                  <>
                    <Skeleton width={180} />
                    <Typography variant="subtitle2">
                      <Skeleton width={120} />
                    </Typography>
                  </>
                ) : (
                  <>
                    <YTHighlightBadge
                      size="small"
                      variant="outlined"
                      style={{
                        color:
                          type === CommentType.POSITIVE ? green[900] : red[900],
                      }}
                      label={`The most ${type} comment`}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        gap: "4px",
                      }}
                    >
                      <Link
                        href={snippet!.authorChannelUrl ?? undefined}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="text.primary"
                        sx={{ textDecoration: "none" }}
                      >
                        <Typography
                          variant="subtitle2"
                          variantMapping={{
                            subtitle2: "span",
                          }}
                        >
                          {snippet!.authorDisplayName}
                        </Typography>
                      </Link>
                      {snippet && snippet.publishedAt && (
                        <Link
                          href={`${videoUrl}&lc=${commentData!.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          color="text.secondary"
                          sx={{
                            textDecoration: "none",
                            color: "#606060",
                            "&:hover": {
                              color: "#0f0f0f",
                            },
                          }}
                        >
                          <Typography variant="caption">
                            {timeAgo.format(new Date(snippet.publishedAt))}
                            {snippet!.updatedAt !== snippet.publishedAt && (
                              <> (edited)</>
                            )}
                          </Typography>
                        </Link>
                      )}
                    </Box>
                  </>
                )
              }
              secondary={
                isFetching ? (
                  <Skeleton variant="rounded" height={75} />
                ) : (
                  <RenderComment
                    text={snippet!.textDisplay || "* no comment text *"}
                  />
                )
              }
              secondaryTypographyProps={{
                variant: "body2",
                color: "textPrimary",
              }}
            />
            {isFetching ? (
              <Skeleton variant="rounded" width={50} height={30} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  marginTop: 1,
                }}
              >
                <ThumbUp
                  fontSize="small"
                  sx={{
                    marginRight: 1,
                  }}
                />
                <Typography variant="caption">{snippet!.likeCount}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </ListItem>
    </div>
  );
};
