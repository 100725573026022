/* eslint-disable react/jsx-no-target-blank */
import {
  Box,
  Container,
  CssBaseline,
  SvgIcon,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Comments } from "./Comments";
import { Form } from "./Form";
import { Info } from "./Info";
import { Login } from "./Login";
import { SentimentChartData } from "./SentimentChartData";
import { analyzeCommentsFreeDemoData, videoInfoDemoData } from "./queries";
import { demoVideoUrl } from "./stores";
import SubscribeDialog from "./SubscribeDialog";
import FeedbackIcon from "@mui/icons-material/Feedback";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});
queryClient.setQueryData(["youtube-oembed", demoVideoUrl], videoInfoDemoData); // cspell:words oembed
queryClient.setQueryData(
  [`my-api`, `comments/${demoVideoUrl}/free`],
  analyzeCommentsFreeDemoData
);

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#065fd4", // "Upload Videos" button from Channel dashboard
      },
      secondary: {
        main: "#c00", // left menu colors from YouTube Studio
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <Container
          maxWidth="xs"
          style={{
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
          }}
        >
          <Typography variant="h4" gutterBottom>
            <SvgIcon
              sx={{
                marginRight: 1.5,
                verticalAlign: "middle",
                marginTop: "-0.2em",
              }}
              fontSize="large"
            >
              {/* design/logo.min.svg */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="240"
                height="240"
                viewBox="0 0 180 180"
              >
                <path
                  fill="red"
                  d="M33.333 0h113.334A33.333 33.333 45 0 1 180 33.333v113.334A33.333 33.333 135 0 1 146.667 180H33.333A33.333 33.333 45 0 1 0 146.667V33.333A33.333 33.333 135 0 1 33.333 0Z"
                />
                <path
                  fill="#fff"
                  d="M119.347 32.167v113.937c0 12.76 8.794 19.854 18.126 19.854 8.63 0 18.126-6.043 18.126-19.854V33.032c0-11.678-8.63-18.988-18.126-18.988s-18.126 8.062-18.126 18.126zM71.875 90v56.103c0 12.76 8.793 19.854 18.126 19.854 8.63 0 18.126-6.043 18.126-19.854v-55.24c0-11.68-8.634-18.99-18.126-18.99-9.496 0-18.126 8.067-18.126 18.127zm-11.22 57.83a18.133 18.133 0 0 1-18.127 18.127 18.133 18.133 0 0 1-18.126-18.126 18.133 18.133 0 0 1 18.126-18.126 18.133 18.133 0 0 1 18.126 18.126"
                />
              </svg>
            </SvgIcon>
            YouTube Analytics
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Use <strong>AI</strong> to check the <strong>sentiment</strong>{" "}
            (mood) of <strong>comments</strong> on any YouTube videos for free!
          </Typography>

          <Box marginTop={2}>
            <Typography variant="h6" gutterBottom>
              Get insights from your audience
            </Typography>
            <Typography variant="body2" gutterBottom>
              You've made it on YouTube? Too many comments to read? Use AI to
              get aggregated sentiment analysis of your audience feedback and
              stay ahead of the game.
            </Typography>
          </Box>

          <Box marginTop={2}>
            <Typography variant="h6" gutterBottom>
              Understand your competitors
            </Typography>
            <Typography variant="body2" gutterBottom>
              Want to beat your competitors? Analyze their audience feedback and
              learn from their mistakes to make your content even better.
            </Typography>
          </Box>

          <Box marginTop={2}>
            <Typography variant="h6" gutterBottom>
              Keep your reputation in check
            </Typography>
            <Typography variant="body2" gutterBottom>
              Identify negative comments early and respond to them before they
              go viral. Keep your audience happy and engaged.
            </Typography>
          </Box>

          <Form />
          <Info />
          <SentimentChartData />
          <Comments />
          {false && ( // not used for now, might need this later to allow analyzing more comments? for free? IDK
            <GoogleOAuthProvider clientId={process.env.REACT_APP_YT_CLIENT_ID!}>
              <Login />
            </GoogleOAuthProvider>
          )}
          <Box marginTop={20}>
            <Typography variant="body2">
              Made in 2024 by{" "}
              <a
                target="_blank"
                rel="noopener"
                href="https://maxim.mazurok.com/?utm_source=yc-comments&utm_placement=footer"
              >
                Maxim Mazurok
              </a>{" "}
              with ❤️ in Sydney, Australia
            </Typography>
          </Box>
          <Box marginTop={5}>
            <Typography variant="caption">
              youtube-analytics.com is not affiliated with, endorsed by, or in
              any way officially connected with YouTube or Google LLC. YouTube
              is a registered trademark of Google LLC."
            </Typography>
          </Box>
        </Container>
        <SubscribeDialog />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
